/*
* ファイルアップロード用
*/
$(function() {

  /*
  * 単画像アップロード
  * reg_file_name：DBに保存されていたファイル名
  * original_file_name：アップロードされたファイル名
  * file_name：ファイルアップロードコントローラー
  * file_data：base64エンコードされた画像の文字列
  */
  $(document).on('change', '.btnUploadFile', function(e) {
    // プレビューターゲット取得
    let targetWrap = $(this).closest('.uploadImage');
    if (targetWrap) {
      // 選択画像読み込み
      let reader = new FileReader();
      reader.onload = function(e) {
        // 元ファイル名取得
        let regFilename = $(targetWrap).find('.btnUploadFile').prop('files')[0]['name'];
        $(targetWrap).find('[name="original_file_name"]').val(regFilename);
        // 表示用base64取得
        let base64Image = e.target.result;
        // 画像表示
        $(targetWrap).find('.previewImage').attr('src', base64Image);
        $(targetWrap).find('.wrapPreviewImage').removeClass('d-none');
        $(targetWrap).find('.wrapPreviewImage').addClass('d-block');
        // 変更状態セット
        $(targetWrap).find('.changeState').val('changed');
        // 削除ボタン表示
        $(targetWrap).find('.btnDeleteFile').removeClass('d-none');
        $(targetWrap).find('.btnDeleteFile').removeClass('d-inline');
        // 元に戻すボタン
        if ($(targetWrap).find('.regFileName').val()=='') {
          // 元ファイルなし
          $(targetWrap).find('.btnUndoFile').removeClass('d-inline');
          $(targetWrap).find('.btnUndoFile').addClass('d-none');
        }
        else {
          // 元ファイルあり
          $(targetWrap).find('.btnUndoFile').removeClass('d-none');
          $(targetWrap).find('.btnUndoFile').addClass('d-inline');
        }
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  });

  /*
  * アップロード画像削除
  */
  $(document).on('click', '.btnDeleteFile', function() {
    let targetWrap = $(this).closest('.uploadImage');
    $(targetWrap).find('.btnUploadFile').val('');
    // 非表示
    $(targetWrap).find('.wrapPreviewImage').removeClass('d-block');
    $(targetWrap).find('.wrapPreviewImage').addClass('d-none');
    // 変更状態セット
    $(targetWrap).find('.changeState').val('deleted');
    // 削除ボタン
    $(this).removeClass('d-inline');
    $(this).addClass('d-none');
    // もとに戻すボタン
    if ($(targetWrap).find('.regFileName').val()!='') {
      $(targetWrap).find('.btnUndoFile').removeClass('d-none');
      $(targetWrap).find('.btnUndoFile').addClass('d-inline');
    }
  });

  /*
  * アップロード画像を元に戻す
  */
  $(document).on('click', '.btnUndoFile', function() {
    let targetWrap = $(this).closest('.uploadImage');
    // 元ファイル名取得
    // let regFileName = $(targetWrap).find('[name="reg_file_name"]').val();
    let regFileName = $(targetWrap).find('.regFileName').val();
    // 元のファイル名があるか判定
    if (regFileName!='') {
      // 元のファイル名がある
      $(targetWrap).find('.previewImage').attr('src', '/storage/'+regFileName);
      $(targetWrap).find('.wrapPreviewImage').removeClass('d-none');
      $(targetWrap).find('.wrapPreviewImage').addClass('d-block');
    }
    else {
      // 元のファイル名無し
      $(targetWrap).find('.previewImage').attr('src', '');
    }
    $(targetWrap).find('.changeState').val('no_change');
    // もとに戻すボタン
    $(targetWrap).find('.btnUndoFile').removeClass('d-inline');
    $(targetWrap).find('.btnUndoFile').addClass('d-none');
  });

});
